import _ from "lodash";
import moment from "moment-timezone";
import { IOfferCategory, IOfferCategoryResponse } from "../models/Category";
import { offerSubmissionApi } from "../shared/constants";
import offerService from "../features/offer-submission/services/OffersService";
import { IOfferGeneratedContent } from "../features/offer-submission/pages/OfferSubmission";
import { IOfferPromotion } from "../models/Promotion";
import index from "../types/react-schema-form";
import { ErrorMessage } from "formik";
import { removeIndexDataPath } from "../features/offer-submission/pages/ErrorSummary";
import { ISponsor } from "../models/Sponsor";
// tslint:disable-next-line:no-var-requires
const Ajv = require("ajv");

export enum OfferDateContext {
  Staged = "staged",
  Live = "live",
  Expired = "expired",
  notExpired = "notExpired",
}

export enum OfferType {
  Buy = "buy",
  Spend = "spend",
  AmCashEarn = "amCashEarn",
  AmCashDiscount = "amCashDiscount",
  Custom = "custom",
}

export enum Category {
  offerCategory1 = "offerCategory1",
  offerCategory2 = "offerCategory2",
}

export enum Availability {
  InStore = "inStore",
  Online = "online",
  None = "none",
}

export enum ProgramType {
  TraditionalCore = "traditionalcore",
  CardLinkedOffers = "cardlinked",
  Airmilesshops = "airmilesshops",
  BmoPreApp = "bmopreapp",
}

// possible values for form selection
export enum Audience {
  Mass = "mass",
  Targeted = "targeted",
  Event = "event",
}

// possible values for mapping to massOffer
export enum AudienceType {
  Mass = "true",
  Targeted = "false",
  Event = "false",
}

export enum DurationUnit {
  Days = "days",
  Months = "months",
  Years = "years",
}

export enum Qualifier {
  Product = "product",
  Fuel = "fuel",
  Storewide = "storewide",
  Category = "category",
  CashRedemption = "cashRedemption",
  CashDiscount = "cashDiscount",
  Custom = "custom",
}

export enum AwardType {
  FlatMiles = "flatMiles",
  MultiplierMiles = "multiplierMiles",
  FlatDiscount = "flatDiscount",
  PercentDiscount = "percentDiscount",
  CashDiscount = "cashDiscount",
  CentsOff = "centsOff",
  Custom = "custom",
}

export enum ValidationSchemas {
  MechanismObject = "MechanismObject.json",
  TierObject = "TierObject.json",
  PostOfferFormObject = "PostOfferFormObject.json",
  PutOfferFormObject = "PutOfferFormObject.json",
  PostBulkOffersFormObject = "PostBulkOfferFormObject.json",
  PutBulkOffersFormObject = "PutBulkOfferFormObject.json",
}

export enum MechanismType {
  NoAction = "noAction",
  BarcodeUPC = "barcodeUPC",
  Barcode128 = "barcodeCODE128",
  PLU = "plu",
  CouponCode = "couponCode",
  Button = "button",
  LoadAndGo = "load+Go",
  OptIn = "optIn",
}

export enum Region {
  AB = "AB",
  BC = "BC",
  MB = "MB",
  NB = "NB",
  NL = "NL",
  NS = "NS",
  NT = "NT",
  NU = "NU",
  ON = "ON",
  PE = "PE",
  QC = "QC",
  SK = "SK",
  YT = "YT",
  TB = "TB",
  ALL = "ALL",
}

export enum OfferStatus {
  Draft = "draft",
  Published = "published",
  Updated = "updated",
}

export enum OfferLimitation {
  NoLimit = "noLimit",
  PerCollector = "perCollector",
  PerCollectorPerTransaction = "perCollectorPerTransaction",
  PerCollectorPerDay = "perCollectorPerDay",
  Custom = "custom",
}
export interface IOfferFormModel {
  id: string;
  duplicatedFrom?: string;
  contentfulId?: string;
  detailsId?: string;
  partnerId: string;
  partnerName: string;
  availability?: Availability[];
  partnerBaseEarnRate?: number;
  baseCashRedemption?: number;
  displayDate: string | Date;
  startDate: string | Date;
  endDate: string | Date;
  firstQualificationDate?: string | Date;
  lastQualificationDate?: string | Date;
  eligibilityDuration?: number;
  eventBasedOffer: boolean;
  eligibilityDurationUnit?: DurationUnit;
  offerType: OfferType;
  qualifier: Qualifier;
  awardType: AwardType;
  awardShort?: ILocalizedObject;
  qualifierShort?: ILocalizedObject;
  issuanceCode?: string;
  mechanismInstructions?: ILocalizedObject;
  mechanisms: IMechanismObject[];
  cashierInstruction?: ILocalizedObject;
  tiers: ITierObject[];
  displayPriority?: number;
  regions: Region[];
  offerLimitation?: OfferLimitation;
  programType?: ProgramType;
  cardType?: string[];
  offerLimitationText?: ILocalizedObject;
  includedLocations?: ILocalizedObject[];
  excludedLocation?: ILocalizedObject[];
  includedBanners?: ILocalizedObject[];
  excludedBanners?: ILocalizedObject[];
  canBeCombined?: boolean;
  combinationsText?: ILocalizedObject;
  exclusions?: ILocalizedObject;
  partnerUrl?: ILocalizedObject;
  daysToApply?: number;
  trademarkInfo?: ILocalizedObject;
  description?: ILocalizedObject;
  tags?: string[];
  partnerLegalName?: ILocalizedObject;
  hasCustomLegal?: boolean;
  legalText?: ILocalizedObject;
  print?: boolean;
  image?: ILocalizedObject<{ path: string | undefined }>;
  status?: OfferStatus;
  createdAt?: string | Date;
  createdBy?: string;
  updatedAt?: string | Date;
  updatedBy?: string;
  publishedAt?: string | Date;
  publishedBy?: string;
  massOffer?: boolean;
  audience: Audience;
  offerCategory1?: string;
  offerCategory2?: string;
  offerCategory3?: string;
  productName?: string;
  productBrand?: string;
  programPriority?: string;
  active?: boolean;
  partnerOfferId?: string;
  mechanismText?: string;
  mechanismTitle?: string;
  campaignCode?: string;
  activeSponsorCodesForSelectedPartnerId?: ISponsor[];
  sponsorCode?: string;
  flagSponsorCode?: boolean;
  partnerInternalAirmilesCalculated?: boolean;
}

export interface IOfferFormModelWithNames extends IOfferFormModel {
  tagNames?: ILocalizedObject[];
  offerCategory1Name?: ILocalizedObject;
  offerCategory2Name?: ILocalizedObject;
  offerCategory3Name?: ILocalizedObject;
}
export interface ILocalizedObject<T = string | undefined> {
  "en-US": T; // "en-US"
  "fr-CA"?: T; // "fr-CA"
}

export interface ITiersContentObject<T = string | undefined> {
  "en-US": T;
  "fr-CA"?: T;
  productSKU?: string;
  skus?: string[];
  upc?: string;
  masterProduct?: boolean;
}

export interface ITierObject {
  awardValue?: number;
  qualifierValue?: number;
  qualifierFrequency?: number;
  content?: ITiersContentObject[];
  qualifierLong?: ILocalizedObject;
  awardLong?: ILocalizedObject;
}

export interface IMechanismObject {
  mechanismType: MechanismType;
  mechanismLabel?: ILocalizedObject;
  mechanismValue?: ILocalizedObject;
  mechanismText?: ILocalizedObject;
  mechanismTitle?: ILocalizedObject;
}

/**
 *   keyword: 'required',
 *   dataPath: '',
 *   schemaPath: '#/required',
 *   params: { missingProperty: 'availability' },
 *   message: 'should have required property \'availability\''
 */
export interface IValidationErrors {
  keyword: string;
  dataPath: string;
  schemaPath: string;
  schema: any;
  parentSchema: any;
  params: { [key: string]: string };
  message: string;
  data: any;
}

const qualifierLongMaxLength = 256;

export class Validator {
  private ajv: any;

  /* TODO: copied from original:
   *  currently check digit validation is performed only on barcode belows of the below types and length
   *  a more comprehensive and standardized set of requirements would be preferred
   *  barcodeCheckDigits contains an array of barcodeValue lengths to perform checkDigit validation on
   *
   */

  private barcodeCheckDigits: { [key: string]: any } = {
    barcodeCODE128: [],
    barcodeUPC: [12],
  };

  private barcodePatterns: { [key: string]: RegExp } = {
    barcodeCODE128: /^[A-Za-z0-9]{5,20}$/,
    barcodeUPC: /^[0-9]{11,12}$/,
  };

  constructor() {
    this.ajv = new Ajv({
      $data: true,
      allErrors: true,
      useDefaults: true,
      verbose: true,
      coerceTypes: true,
    });

    // TODO: Make sure these files are actually being copied to the build folder
    // Set up Schema
    this.ajv.addSchema([
      require("./schemas/MechanismObject.json"),
      require("./schemas/TierObject.json"),
      require("./schemas/PostOfferFormObject.json"),
      require("./schemas/PutOfferFormObject.json"),
      require("./schemas/PostBulkOfferFormObject.json"),
      require("./schemas/PutBulkOfferFormObject.json"),
    ]);
  }

  /**
   * Validates a given data against a provided schema name
   */
  public validate<T = any>(data: T, schemaName: string): IValidationErrors[] {
    try {
      const isValid = this.ajv.validate(schemaName, data);
      if (isValid) {
        // tslint:disable-next-line:no-console
        // console.debug("is valid");
        return [];
      } else {
        // remove the errors that are IFs or anyOfs
        const errors = this.ajv.errors.filter(
          (error: IValidationErrors) =>
            !(error.keyword === "if" || error.keyword === "anyOf")
        );

        // tslint:disable-next-line:no-console
        // console.error(errors);
        return errors;
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      // console.error(error);
      throw error;
    }
  }

  public validateBarcode(barcodeValue: string, barcodeType: string): boolean {
    // ensure value is populated, a validation pattern exists, and matches barcode pattern according to its type
    if (
      !_.isEmpty(barcodeValue) &&
      _.has(this.barcodePatterns, barcodeType) &&
      this.barcodePatterns[barcodeType].test(barcodeValue)
    ) {
      if (this.barcodeCheckDigits[barcodeType]) {
        this.barcodeCheckDigits[barcodeType].forEach((digit: number) => {
          if (barcodeValue.length === digit) {
            const digits = _.split(_.padStart(barcodeValue, 18, "0"), "");
            const checkDigit = _.toInteger(digits.pop());
            const checkSum = _.reduce(
              digits,
              (sum, n, i) => {
                return sum + (i % 2 === 0 ? 3 : 1) * _.toInteger(n);
              },
              0
            );
            return _.ceil(checkSum, -1) - checkSum === checkDigit;
          }
        });
      }
      // if no checkdigits configured for barcodeType or length of value is no configured
      return true;
    }
    return false;
  }

  public getCardTypeEmptyErrors(cardType: string[] | undefined) {
    const validationErrors: IValidationErrors[] = [];
    let errorMessage = null;
    if (!cardType || cardType.length === 0) {
      errorMessage =
          "Card Type field is mandatory.";
      validationErrors.push(
          this.generateInvalidFormatError(
              errorMessage,
              "cardTypeEmpty",
              "cardType"
          )
      );
    }
    return validationErrors;
  }

    public getCardTypeValidValuesErrors(
        cardType: string[] | undefined,
        programType: string | undefined
    ): IValidationErrors[] {
        const validationErrors: IValidationErrors[] = [];

        const validCombinations: string[][] = [
                ["NonBmoMastercard", "BmoMastercard"],
                ["NonBmoMastercard", "BmoMastercard", "BmoDebit"],
                ["BmoDebit"]
        ];

        const isValidCombination = (cardTypeArray: string[]): boolean => {
            return validCombinations.some(validSet =>
            validSet.length === cardTypeArray.length &&
            validSet.every(card => cardTypeArray.includes(card))
            );
        };

        if (cardType && !isValidCombination(cardType)) {
            validationErrors.push(
                this.generateCustomValidationError
                (
                    "cardtype",
                    ".cardType",
                    "Invalid cardType combination.",
                    cardType.length > 0 ? cardType.join(", ") : "No card types provided",
                   "(Non-BMO Mastercard + BMO Mastercard) + (BMO Debit, Non-BMO Mastercard + BMO Mastercard), (BMO Debit)"
                )
            );
        }
          return validationErrors;
    }

    public generateCustomValidationError(
        keyword: string,
        dataPath: string,
        message: string,
        data: string,
        allowedValue?: string
    ): IValidationErrors {
        return {
            keyword,
            dataPath,
            schemaPath: "",
            schema: "",
            parentSchema: "",
        params: {
            allowedValues: allowedValue ? allowedValue : "",
        },
        message,
        data,
        };
    }


  public getLastQualificationDateErrors(lastQualificationDate: any, firstQualificationDate: any) {
    const validationErrors: IValidationErrors[] = [];
    let errorMessage = null;

    if (firstQualificationDate) {
      if (lastQualificationDate < firstQualificationDate) {
        errorMessage =
            "Last qualification date cannot be before First qualification date.";
        validationErrors.push(
            this.generateInvalidDateError(
                errorMessage,
                "lastQualificationDateLowerThanFirstQualificationDate",
                "lastQualificationDate"
            )
        );
      }
    }

    return validationErrors;
  }

  public getEligibilityErrors(eligibilityDuration: number | undefined) {
    const validationErrors: IValidationErrors[] = [];
    let errorMessage = null;
    if (eligibilityDuration && !Number.isInteger(eligibilityDuration)) {
      errorMessage =
          "Collector's eligibility duration must be an integer value.";
      validationErrors.push(
          this.generateInvalidFormatError(
              errorMessage,
              "eligibilityDurationInteger",
              "eligibilityDuration"
          )
      );
    }

    return validationErrors;
  }

  public getEligibilityEmptyErrors(eligibilityDuration: number | undefined) {
    const validationErrors: IValidationErrors[] = [];
    let errorMessage = null;

    if (eligibilityDuration === undefined || !eligibilityDuration || eligibilityDuration.toString() === "") {
      errorMessage =
          "Collector's eligibility duration is mandatory.";
      validationErrors.push(
          this.generateInvalidFormatError(
              errorMessage,
              "eligibilityDurationEmpty",
              "eligibilityDuration"
          )
      );
    }

    return validationErrors;
  }

  public getDateErrors(
    startDate: any,
    endDate: any,
    displayDate: any
  ): IValidationErrors[] {
    const validationErrors: IValidationErrors[] = [];
    let errorMessage = null;
    if (startDate < displayDate) {
      errorMessage = "Start date cannot be before display date.";
      validationErrors.push(
        this.generateInvalidDateError(
          errorMessage,
          "startDateLessThanDisplayDate",
          "startDate"
        )
      );
    } else if (startDate > endDate) {
      errorMessage = "Start date cannot be after end date.";
      validationErrors.push(
        this.generateInvalidDateError(
          errorMessage,
          "startDateGreaterThanEndDate",
          "startDate"
        )
      );
    } else if (displayDate > endDate) {
      errorMessage = "Display date cannot be after end date.";
      validationErrors.push(
        this.generateInvalidDateError(
          errorMessage,
          "displayDateGreaterThanEndDate",
          "displayDate"
        )
      );
    } else if (moment(endDate).isBefore(moment.utc())) {
      errorMessage =
        "End date cannot be before current date. To disable an Offer, go to My Offers or Preview page, and select Disable.";
      validationErrors.push(
        this.generateInvalidDateError(
          errorMessage,
          "endDateGreaterThanCurrentDate",
          "endDate"
        )
      );
    }

    return validationErrors;
  }
  public getProductSKUErrors(tiers: ITierObject[]): IValidationErrors[] {
    const validationErrors: IValidationErrors[] = [];
    let errorMessage = null;

    if (tiers !== undefined) {
      for (let i = 0; i < tiers.length; i++) {
        let content = tiers[i].content;
        if (content !== undefined) {
          for (let j = 0; j < content.length; j++) {
            if (content[j].productSKU !== undefined) {
              errorMessage =
                "productSKU is deprecated. Use the new column skus instead.";
              validationErrors.push(
                this.generateProductSkuErrors(
                  errorMessage,

                  "tiers[" + i + "]. content[" + j + "].productSKU"
                )
              );
            }
          }
        }
      }
    }

    return validationErrors;
  }

  public getBarcodeErrors(mechanisms: IMechanismObject[]): IValidationErrors[] {
    const validationErrors: IValidationErrors[] = [];

    // For each mechanism determine if the mechanism has an invalid barcode
    _.forEach(mechanisms, (mechanism, index) => {
      if (
        mechanism &&
        [MechanismType.Barcode128, MechanismType.BarcodeUPC].includes(
          mechanism.mechanismType
        ) &&
        mechanism.mechanismValue
      ) {
        if (
          mechanism.mechanismValue["en-US"] &&
          !this.validateBarcode(
            mechanism.mechanismValue["en-US"],
            mechanism.mechanismType
          )
        ) {
          validationErrors.push(
            this.generateInvalidBarcodeError(
              mechanism.mechanismValue["en-US"],
              index
            )
          );
        }
      }
    });
    return validationErrors;
  }

  public getProgramTypeErrors(mechanisms: IMechanismObject[], programType: string): IValidationErrors[] {
    const validationErrors: IValidationErrors[] = [];

    // For each mechanism determine if the mechanism has an invalid program type
    _.forEach(mechanisms, (mechanism, index) => {
      if (
          programType == 'cardlinked' &&
          [MechanismType.Button, MechanismType.Barcode128, MechanismType.BarcodeUPC, MechanismType.CouponCode
          ].includes(
              mechanism.mechanismType
          )
      ) {
        validationErrors.push(
            this.generateInvalidMechanismTypeForProgramTypeError(
                mechanism.mechanismType,
                index
            )
        );
      }
    });
    return validationErrors;
  }

  public getIssuanceSponsorErrors(offer: Partial<IOfferFormModel>,featureFlagBulkUpload: boolean): IValidationErrors[] {

    const validationErrors: IValidationErrors[] = [];
    const issuanceCode = _.get(offer, "issuanceCode");
    const sponsorCode = _.get(offer, "sponsorCode");

    const issuanceValidation = ()  => {
      if (!issuanceCode || issuanceCode.length === 0 ){
        validationErrors.push(
          this.generateCustomRequiredError(
            "issuanceCode"
          )
        );
      }
    }
    const sponsorValidation = () => {
      if (!sponsorCode || sponsorCode.length === 0){
        validationErrors.push(
          this.generateCustomRequiredError(
            "sponsorCode",
          )
        );
      }
    }

    const issuanceCodeNotRequired = ()  => {
      if((issuanceCode || "").length > 0){
        validationErrors.push(
            this.generateInvalidFormatError(
                "issuanceCode is not required.",
                "issuanceCodeNotRequired",
                "issuanceCode"
            )
        );
      }
    }

    const sponsorCodeNotRequired = ()  => {
      if((sponsorCode || "").length > 0){
        validationErrors.push(
            this.generateInvalidFormatError(
                "sponsorCode is not required.",
                "sponsorCodeNotRequired",
                "sponsorCode"
            )
        );
      }
    }

    const offerType = _.get(offer, "offerType");
    const awardType = _.get(offer, "awardType");
    const audience = _.get(offer, "audience");
    const mechanism = _.get(offer, "mechanisms");
    const internalCalculated = _.get(offer, "partnerInternalAirmilesCalculated");

    //anything except xxx(flatdiscount or percent discount)
    const validOfferAwardCombo = offerType !== OfferType.AmCashDiscount &&
      awardType &&
      ![AwardType.FlatDiscount, AwardType.PercentDiscount, AwardType.Custom].includes(
        awardType
      );

    //xxx(cash miles off,% off,$ off)
    const bulkValidOfferAwardCombo = offerType === OfferType.AmCashDiscount ||
        awardType &&
        [AwardType.FlatDiscount, AwardType.PercentDiscount].includes(
            awardType
        );

    const isOfferOptin = ():boolean => {
      if(mechanism){
        return mechanism.some(
          (value) => value.mechanismType === MechanismType.OptIn
        );
      }
      return false;
    }

    if (isOfferOptin() && validOfferAwardCombo && internalCalculated) {
        // OFD-2649 Remove restriction on sponsor/issuance code for non-RICE partners (internal-airmiles-calculated = False)
        issuanceValidation();
        sponsorValidation();
    }

    if (
      internalCalculated &&
      audience &&
      [Audience.Targeted, Audience.Event].includes(audience) &&
      validOfferAwardCombo
    ){
      issuanceValidation();
      sponsorValidation();
    }
    if(featureFlagBulkUpload){
      //if awardtype is (flatdiscount or percent discount) than sponsor and issuance code are not required
      if(bulkValidOfferAwardCombo){
        issuanceCodeNotRequired();
        sponsorCodeNotRequired();
      }
    }


    return validationErrors;
  }
  /**
   * Returns an array of errors against the object
   */
  public getErrorsText(errors: IValidationErrors[]) {
    return this.ajv.errorsText(errors);
  }

  public getQualifierLongErrors(
    offer: Partial<IOfferFormModel>,
    offerGeneratedContent: IOfferGeneratedContent
  ) {
    const result: IValidationErrors[] = [];
    const generatedContent = offerGeneratedContent.tiersGeneratedContent;

    if (generatedContent) {
      generatedContent.forEach((generatedContentByIndex, tierIndex) => {
        if (generatedContentByIndex.qualifierLong) {
          Object.keys(generatedContentByIndex.qualifierLong).forEach(
            (key: string) => {
              if (
                key &&
                (key === "en-US" || key === "fr-CA") &&
                generatedContentByIndex.qualifierLong[key]
              ) {
                const qualifierLongValue =
                  generatedContentByIndex.qualifierLong[key];
                if (
                  qualifierLongValue &&
                  qualifierLongValue.length > qualifierLongMaxLength
                ) {
                  let isCustom = false;
                  if (
                    offer.offerType === "custom" &&
                    offer.qualifier === "custom"
                  ) {
                    isCustom = true;
                  }

                  const arrayToLoop = isCustom
                    ? Object.keys(
                        _.get(offer, `tiers[${tierIndex}].qualifierLong`)
                      )
                    : _.get(offer, `tiers[${tierIndex}].content`);
                  arrayToLoop.forEach((value: any, contentIndex: number) => {
                    const qualifierLongLanguageErrors: IValidationErrors = {
                      keyword: "qualifierLong",
                      dataPath: `.tiers[${tierIndex}]${
                        isCustom
                          ? `.qualifierLong[${value}]`
                          : `.content[${contentIndex}]['${key}']`
                      }`,
                      schemaPath: `TierObject.json/properties/qualifierLong/properties/${
                        isCustom ? value : key
                      }/maxLength`,
                      schema: "",
                      parentSchema: {
                        type: "string",
                        maxLength: qualifierLongMaxLength,
                      },
                      params: { limit: `${qualifierLongMaxLength}` },
                      message: `The length of all products should NOT be longer than ${qualifierLongMaxLength} characters`,
                      data: generatedContentByIndex.qualifierLong[key],
                    };
                    result.push(qualifierLongLanguageErrors);
                  });
                }
              }
            }
          );
        }
      });
    }

    return result;
  }

  public getCategoryErrors(
    data: any,
    categories: IOfferCategory[]
  ): IValidationErrors[] {
    const validationErrors: IValidationErrors[] = [];

    // For each offer determine if the offers categories are set
    const offerCategory1 = _.get(data, Category.offerCategory1);
    const offerCategory2 = _.get(data, Category.offerCategory2);
    const qualifier = _.get(data, "qualifier");
    const category = _.find(categories, { id: offerCategory1 });
    const childCategories = _.get(category, "childCategories", []);
    const childCategory = _.find(childCategories, { id: offerCategory2 });

    if (_.isEmpty(category)) {
      validationErrors.push(
        this.generateCustomRequiredError(
          Category.offerCategory1,
          offerCategory1,
          "category value is required and must be a valid id."
        )
      );
    } else if (
      [
        Qualifier.Product,
        Qualifier.Custom,
        Qualifier.Category,
        Qualifier.Fuel,
      ].includes(qualifier)
    ) {
      // The error for offerCategory2 will only exist when there are child categories to validate against
      if (childCategories && childCategories.length) {
        // The offerCategory2 is not a required field, but if it is set, the value should map to the parent
        if (!_.isEmpty(offerCategory2) && _.isEmpty(childCategory)) {
          validationErrors.push(
            this.generateCustomRequiredError(
              Category.offerCategory2,
              offerCategory2,
              "category value does not map to the parent."
            )
          );
        }
      } else {
        // There should not be a value for offerCategory2 because although it is optional, there must be childCategories that map to it
        if (!_.isEmpty(offerCategory2)) {
          validationErrors.push(
            this.generateCustomRequiredError(
              Category.offerCategory2,
              offerCategory2,
              "category value is not required as there is no child categories."
            )
          );
        }
      }
    } else if (
      ![
        Qualifier.Product,
        Qualifier.Custom,
        Qualifier.Category,
        Qualifier.Fuel,
      ].includes(qualifier) &&
      !_.isEmpty(offerCategory2)
    ) {
      validationErrors.push(
        this.generateCustomRequiredError(
          Category.offerCategory2,
          offerCategory2,
          "category value should not be set for this qualifier type."
        )
      );
    }
    return validationErrors;
  }

  public getPromotionErrors(
    data: any,
    promotions: IOfferPromotion[]
  ): IValidationErrors[] {
    const validationErrors: IValidationErrors[] = [];

    // We currently support only the validation for the first promotion id
    const promoId = _.get(data, "tags[0]");
    if (promoId && promoId.length > 0) {
      const promotion: IOfferPromotion | undefined = promotions.find(
        (promo) => promo.id === promoId
      );
      let errorMessage = null;

      if (promotion) {
        if (!promotion.active) {
          errorMessage = "promotion is not active.";
        }
      } else {
        errorMessage = "promotion id must be a valid id.";
      }

      if (errorMessage) {
        validationErrors.push({
          keyword: "promotionIsInvalid",
          dataPath: ".tags",
          schemaPath: "",
          schema: "",
          parentSchema: "",
          params: {
            missingProperty: `tags`,
          },
          message: errorMessage,
          data: promoId,
        });
      }
    }

    return validationErrors;
  }
  private generateProductSkuErrors(
    errorMessage: string,
    dataPath: string
  ): IValidationErrors {
    return {
      keyword: "",
      dataPath: dataPath,
      schemaPath: "",
      schema: "",
      parentSchema: "",
      params: {},
      message: errorMessage,
      data: "",
    };
  }

  private generateInvalidBarcodeError(
    barcodeValue: string,
    index: number
  ): IValidationErrors {
    return {
      keyword: "barcode",
      dataPath: `.mechanisms[${index}].mechanismValue['en-US']`,
      schemaPath: "",
      schema: "",
      parentSchema: "",
      params: {},
      message: "The Barcode Value is not a valid barcode.",
      data: barcodeValue,
    };
  }

  private generateInvalidMechanismTypeForProgramTypeError(
      mechanismTypeValue: string,
      index: number
  ): IValidationErrors {
    return {
      keyword: "mechanismType",
      dataPath: `.mechanisms[${index}].mechanismType`,
      schemaPath: "",
      schema: "",
      parentSchema: "",
      params: {},
      message: "Card Linked Offers cannot have button, barcode, couponCode mechanic. Only noAction or optIn is allowed.",
      data: mechanismTypeValue,
    };
  }

  private generateInvalidDateError(
    errorMessage: string,
    keyword: string,
    dataPath: string
  ): IValidationErrors {
    return {
      keyword: keyword,
      dataPath: dataPath,
      schemaPath: "",
      schema: "",
      parentSchema: "",
      params: {},
      message: errorMessage,
      data: "",
    };
  }

  private generateInvalidFormatError(
      errorMessage: string,
      keyword: string,
      dataPath: string
  ): IValidationErrors {
    return {
      keyword: keyword,
      dataPath: dataPath,
      schemaPath: "",
      schema: "",
      parentSchema: "",
      params: {},
      message: errorMessage,
      data: "",
    };
  }

  private generateCustomRequiredError(
    property: string,
    data?: number,
    customMessage?: string
  ): IValidationErrors {
    return {
      keyword: "required",
      dataPath: ``,
      schemaPath: "",
      schema: "",
      parentSchema: "",
      params: {
        missingProperty: `${property}`,
      },
      message: customMessage || "This field is required",
      data: data || "",
    };
  }
}
